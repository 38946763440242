.offers {
    display: flex;
}

.head_container {
    text-align: center;
}

@media screen and (max-width: 950px) {
    .offers{
        flex-wrap: wrap;
    }
}