.header {
  width: 100%;
  height: 5rem;
  background-color: #adc0d8;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header nav {
  height: 100%;
}

.header div {
  height: 5rem;
}
.header div img {
  height: 100%;
  border: none;
  margin-left: 2rem;
}

.header ul {
  height: 100%;
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
}

.header li {
  margin: 0 1rem;
  width: 5rem;
}

.header a {
  color: white;
  text-decoration: none;
}

.header :not(:first-child) a:hover,
.header :not(:first-child) a:active,
.header :not(:first-child) a.active {
  color: #3f83db;
  padding-bottom: 0.25rem;
  border-bottom: 4px solid #95bcf0;
}

@media screen and (max-width: 600px) {
  .header ul {
    display: flex;
    flex-wrap: wrap;
  }

}
