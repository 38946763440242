.card {
  width: 280px;
  display: block;
  margin: 60px auto;
  box-shadow: 5px 2px 40px 10px #a4a3a7;
  border-radius: 5px;
}
.card .header_title {
  text-transform: uppercase;
  margin: 0;
}
.card_header {
  height: 150px;
  width: 100%;
  padding: 15px;
  background-size: cover;
  color: #fff;
}

.card_header img {
  width: 255px;
}

.card_body {
  padding: 15px;
  background-color: #fff;
  width: 100%;
  border-radius: 5px;
}

.date {
  font-size: 11px;
  font-weight: 600;
  color: grey;
}

.offer_name {
  display: block;
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.hover_underline_animation {
  display: inline-block;
  position: relative;
}
.hover_underline_animation::after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: black;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}
.hover_underline_animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}
