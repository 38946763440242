.picCard {
  margin: 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.picCard img {
  margin: 5px;
  width: 300px;
  height: auto;
  border-radius: 5px;
  box-shadow: 1px 1px 3px 4px #ccc;
  transition: transform 1s, box-shadow 1s;
}

.picCard img:hover {
  transform: scale(1.3);
  cursor: pointer;
  box-shadow: none;
}
